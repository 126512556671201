@import "qw-theme";

/* QW Align */
.qw-align-baseline { vertical-align: baseline }
.qw-align-top      { vertical-align: top }
.qw-align-middle   { vertical-align: middle }
.qw-align-bottom   { vertical-align: bottom }

/*QW Colors*/
.qw-color-text-heading { color: @qw-color-text-heading }
.qw-color-text-body { color: @qw-color-text-body }
.qw-color-text-secondary-heading { color: @qw-color-text-secondary-heading }
.qw-color-text-secondary-body { color: @qw-color-text-secondary-body }
.qw-color-text-inverse-heading { color: @qw-color-text-inverse-heading }
.qw-color-text-inverse-body { color: @qw-color-text-inverse-body }
.qw-color-text-inverse-secondary-heading { color: @qw-color-text-inverse-secondary-heading }
.qw-color-text-inverse-secondary-body { color: @qw-color-text-inverse-secondary-body }
.qw-color-text-link { color: @qw-color-text-link }
.qw-color-text-green { color: @qw-color-green }
.qw-color-text-red { color: @qw-color-red }
.qw-color-text-disabled { color: @qw-color-text-disabled }
.qw-color-background-green { background: @qw-color-green }
.qw-color-background-red { background: @qw-color-red }
.qw-color-background-disabled { background: @qw-color-disabled }
.qw-color-grey-1 { color: @qw-color-grey-1 }

.qw-pending { color: #0F86DB; background-color: #EBF6FF; }
.qw-warning { color: #F2A600; background-color: #FFF8E8; }
.qw-error { color: #C40A0A; background-color: #FFE8E8; }
.qw-success { color: #3B8C06; background-color: #F1FFE8; }
.qw-waiting { color: #8794A3; background-color: #F0F5FA; }

/* QW Border */
.qw-border { border: solid @qw-border-width @qw-color-border; }
.qw-border-top { border-top: solid @qw-border-width @qw-color-border; }
.qw-border-right { border-right: solid @qw-border-width @qw-color-border; }
.qw-border-bottom { border-bottom: solid @qw-border-width @qw-color-border; }
.qw-border-left { border-left: solid @qw-border-width @qw-color-border; }
.qw-border-secondary { border: solid @qw-border-width @qw-color-border-secondary; }
.qw-border-secondary-top { border-top: solid @qw-border-width @qw-color-border-secondary; }
.qw-border-secondary-right { border-right: solid @qw-border-width @qw-color-border-secondary; }
.qw-border-secondary-bottom { border-bottom: solid @qw-border-width @qw-color-border-secondary; }
.qw-border-secondary-left { border-left: solid @qw-border-width @qw-color-border-secondary; }
.qw-border-none { border: 0 }
.qw-small-rounded { border-radius: @qw-border-radius-small } .qw-circle  { border-radius: 50% }
.qw-small-rounded-top    { border-radius: @qw-border-radius-small @qw-border-radius-small 0 0 }
.qw-small-rounded-right  { border-radius: 0 @qw-border-radius-small @qw-border-radius-small 0 }
.qw-small-rounded-bottom { border-radius: 0 0 @qw-border-radius-small @qw-border-radius-small }
.qw-small-rounded-left   { border-radius: @qw-border-radius-small 0 0 @qw-border-radius-small }
.qw-medium-rounded { border-radius: @qw-border-radius-medium }
.qw-medium-rounded-top    { border-radius: @qw-border-radius-medium @qw-border-radius-medium 0 0 }
.qw-medium-rounded-right  { border-radius: 0 @qw-border-radius-medium @qw-border-radius-medium 0 }
.qw-medium-rounded-bottom { border-radius: 0 0 @qw-border-radius-medium @qw-border-radius-medium }
.qw-medium-rounded-left   { border-radius: @qw-border-radius-medium 0 0 @qw-border-radius-medium }
.qw-large-rounded { border-radius: @qw-border-radius-large}
.qw-not-rounded { border-radius: 0 }
.qw-rounded { border-radius: @qw-border-radius-circle }
.qw-border-error { border-color: @qw-color-red }

.qw-flex { display: flex }

.qw-flex-column  { flex-direction: column }
.qw-flex-wrap    { flex-wrap: wrap }

.qw-items-start    { align-items: flex-start }
.qw-items-end      { align-items: flex-end }
.qw-items-center   { align-items: center }
.qw-items-baseline { align-items: baseline }
.qw-items-stretch  { align-items: stretch }

.qw-self-start    { align-self: flex-start }
.qw-self-end      { align-self: flex-end }
.qw-self-center   { align-self: center }
.qw-self-baseline { align-self: baseline }
.qw-self-stretch  { align-self: stretch }

.qw-justify-start   { justify-content: flex-start }
.qw-justify-end     { justify-content: flex-end }
.qw-justify-center  { justify-content: center }
.qw-justify-between { justify-content: space-between }
.qw-justify-around  { justify-content: space-around }

.qw-content-start   { align-content: flex-start }
.qw-content-end     { align-content: flex-end }
.qw-content-center  { align-content: center }
.qw-content-between { align-content: space-between }
.qw-content-around  { align-content: space-around }
.qw-content-stretch { align-content: stretch }

/* 1.qw- Fix for Chrome 44 bug.qw- https://code.qw-google.qw-com/p/chromium/issues/detail?id=506893 */
.qw-flex-auto {
  flex: 1 1 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}
.qw-flex-none { flex: none }

.qw-order-0 { order: 0 }
.qw-order-1 { order: 1 }
.qw-order-2 { order: 2 }
.qw-order-3 { order: 3 }
.qw-order-last { order: 99999 }


/* QW Hide */
.qw-hide {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}
.qw-display-none { display: none !important }

/* QW Layout */
.inline       { display: inline }
.block        { display: block }
.inline-block { display: inline-block }
.table        { display: table }
.table-cell   { display: table-cell }

.overflow-hidden { overflow: hidden }
.overflow-scroll { overflow: scroll }
.overflow-auto   { overflow: auto }

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table
}
.clearfix:after { clear: both }

.left  { float: left }
.right { float: right }

.fit { max-width: 100% }
.border-box { box-sizing: border-box }


/* QW Margin */
.qw-m-none  { margin:        0 }
.qw-mt-none { margin-top:    0 }
.qw-mr-none { margin-right:  0 }
.qw-mb-none { margin-bottom: 0 }
.qw-ml-none { margin-left:   0 }
.qw-mx-none { margin-left:   0; margin-right:  0 }
.qw-my-none { margin-top:    0; margin-bottom: 0 }
.qw-ml-auto { margin-left: auto }
.qw-mr-auto { margin-right: auto }

.qw-m-xxx-small  { margin:        @qw-spacing-xxx-small; margin: @qw-spacing-xxx-small-rem }
.qw-mt-xxx-small { margin-top:    @qw-spacing-xxx-small; margin-top: @qw-spacing-xxx-small-rem }
.qw-mr-xxx-small { margin-right:  @qw-spacing-xxx-small; margin-right: @qw-spacing-xxx-small-rem }
.qw-mb-xxx-small { margin-bottom: @qw-spacing-xxx-small; margin-bottom: @qw-spacing-xxx-small-rem }
.qw-ml-xxx-small { margin-left:   @qw-spacing-xxx-small; margin-left: @qw-spacing-xxx-small-rem }
.qw-mx-xxx-small { margin-left:   @qw-spacing-xxx-small; margin-left: @qw-spacing-xxx-small-rem; margin-right: @qw-spacing-xxx-small; margin-right: @qw-spacing-xxx-small-rem }
.qw-my-xxx-small { margin-top:    @qw-spacing-xxx-small; margin-top: @qw-spacing-xxx-small-rem; margin-bottom: @qw-spacing-xxx-small; margin-bottom: @qw-spacing-xxx-small-rem }

.qw-m-xx-small  { margin:        @qw-spacing-xx-small; margin: @qw-spacing-xx-small-rem }
.qw-mt-xx-small { margin-top:    @qw-spacing-xx-small; margin-top: @qw-spacing-xx-small-rem }
.qw-mr-xx-small { margin-right:  @qw-spacing-xx-small; margin-right: @qw-spacing-xx-small-rem }
.qw-mb-xx-small { margin-bottom: @qw-spacing-xx-small; margin-bottom: @qw-spacing-xx-small-rem }
.qw-ml-xx-small { margin-left:   @qw-spacing-xx-small; margin-left: @qw-spacing-xx-small-rem }
.qw-mx-xx-small { margin-left:   @qw-spacing-xx-small; margin-left: @qw-spacing-xx-small-rem; margin-right: @qw-spacing-xx-small; margin-right: @qw-spacing-xx-small-rem }
.qw-my-xx-small { margin-top:    @qw-spacing-xx-small; margin-top: @qw-spacing-xx-small-rem; margin-bottom: @qw-spacing-xx-small; margin-bottom: @qw-spacing-xx-small-rem }

.qw-m-x-small  { margin:        @qw-spacing-x-small; margin: @qw-spacing-x-small-rem }
.qw-mt-x-small { margin-top:    @qw-spacing-x-small; margin-top: @qw-spacing-x-small-rem }
.qw-mr-x-small { margin-right:  @qw-spacing-x-small; margin-right: @qw-spacing-x-small-rem }
.qw-mb-x-small { margin-bottom: @qw-spacing-x-small; margin-bottom: @qw-spacing-x-small-rem }
.qw-ml-x-small { margin-left:   @qw-spacing-x-small; margin-left: @qw-spacing-x-small-rem }
.qw-mx-x-small { margin-left:   @qw-spacing-x-small; margin-left: @qw-spacing-x-small-rem; margin-right: @qw-spacing-x-small; margin-right: @qw-spacing-x-small-rem }
.qw-my-x-small { margin-top:    @qw-spacing-x-small; margin-top: @qw-spacing-x-small-rem; margin-bottom: @qw-spacing-x-small; margin-bottom: @qw-spacing-x-small-rem }

.qw-m-small  { margin:        @qw-spacing-small; margin: @qw-spacing-small-rem }
.qw-mt-small { margin-top:    @qw-spacing-small; margin-top: @qw-spacing-small-rem }
.qw-mr-small { margin-right:  @qw-spacing-small; margin-right: @qw-spacing-small-rem }
.qw-mb-small { margin-bottom: @qw-spacing-small; margin-bottom: @qw-spacing-small-rem }
.qw-ml-small { margin-left:   @qw-spacing-small; margin-left: @qw-spacing-small-rem }
.qw-mx-small { margin-left:   @qw-spacing-small; margin-left: @qw-spacing-small-rem; margin-right: @qw-spacing-small; margin-right: @qw-spacing-small-rem }
.qw-my-small { margin-top:    @qw-spacing-small; margin-top: @qw-spacing-small-rem; margin-bottom: @qw-spacing-small; margin-bottom: @qw-spacing-small-rem }

.qw-m-medium  { margin:        @qw-spacing-medium; margin: @qw-spacing-medium-rem }
.qw-mt-medium { margin-top:    @qw-spacing-medium; margin-top: @qw-spacing-medium-rem }
.qw-mr-medium { margin-right:  @qw-spacing-medium; margin-right: @qw-spacing-medium-rem }
.qw-mb-medium { margin-bottom: @qw-spacing-medium; margin-bottom: @qw-spacing-medium-rem }
.qw-ml-medium { margin-left:   @qw-spacing-medium; margin-left: @qw-spacing-medium-rem }
.qw-mx-medium { margin-left:   @qw-spacing-medium; margin-left: @qw-spacing-medium-rem; margin-right: @qw-spacing-medium; margin-right: @qw-spacing-medium-rem }
.qw-my-medium { margin-top:    @qw-spacing-medium; margin-top: @qw-spacing-medium-rem; margin-bottom: @qw-spacing-medium; margin-bottom: @qw-spacing-medium-rem }

.qw-m-large  { margin:        @qw-spacing-large; margin: @qw-spacing-large-rem }
.qw-mt-large { margin-top:    @qw-spacing-large; margin-top: @qw-spacing-large-rem }
.qw-mr-large { margin-right:  @qw-spacing-large; margin-right: @qw-spacing-large-rem }
.qw-mb-large { margin-bottom: @qw-spacing-large; margin-bottom: @qw-spacing-large-rem }
.qw-ml-large { margin-left:   @qw-spacing-large; margin-left: @qw-spacing-large-rem }
.qw-mx-large { margin-left:   @qw-spacing-large; margin-left: @qw-spacing-large-rem; margin-right: @qw-spacing-large; margin-right: @qw-spacing-large-rem }
.qw-my-large { margin-top:    @qw-spacing-large; margin-top: @qw-spacing-large-rem; margin-bottom: @qw-spacing-large; margin-bottom: @qw-spacing-large-rem }

.qw-m-x-large  { margin:        @qw-spacing-x-large; margin: @qw-spacing-x-large-rem }
.qw-mt-x-large { margin-top:    @qw-spacing-x-large; margin-top: @qw-spacing-x-large-rem }
.qw-mr-x-large { margin-right:  @qw-spacing-x-large; margin-right: @qw-spacing-x-large-rem }
.qw-mb-x-large { margin-bottom: @qw-spacing-x-large; margin-bottom: @qw-spacing-x-large-rem }
.qw-ml-x-large { margin-left:   @qw-spacing-x-large; margin-left: @qw-spacing-x-large-rem }
.qw-mx-x-large { margin-left:   @qw-spacing-x-large; margin-left: @qw-spacing-x-large-rem; margin-right: @qw-spacing-x-large; margin-right: @qw-spacing-x-large-rem }
.qw-my-x-large { margin-top:    @qw-spacing-x-large; margin-top: @qw-spacing-x-large-rem; margin-bottom: @qw-spacing-x-large; margin-bottom: @qw-spacing-x-large-rem }

.qw-m-xx-large  { margin:        @qw-spacing-xx-large; margin: @qw-spacing-xx-large-rem }
.qw-mt-xx-large { margin-top:    @qw-spacing-xx-large; margin-top: @qw-spacing-xx-large-rem }
.qw-mr-xx-large { margin-right:  @qw-spacing-xx-large; margin-right: @qw-spacing-xx-large-rem }
.qw-mb-xx-large { margin-bottom: @qw-spacing-xx-large; margin-bottom: @qw-spacing-xx-large-rem }
.qw-ml-xx-large { margin-left:   @qw-spacing-xx-large; margin-left: @qw-spacing-xx-large-rem }
.qw-mx-xx-large { margin-left:   @qw-spacing-xx-large; margin-left: @qw-spacing-xx-large-rem; margin-right: @qw-spacing-xx-large; margin-right: @qw-spacing-xx-large-rem }
.qw-my-xx-large { margin-top:    @qw-spacing-xx-large; margin-top: @qw-spacing-xx-large-rem; margin-bottom: @qw-spacing-xx-large; margin-bottom: @qw-spacing-xx-large-rem }

.qw-m-xxx-large  { margin:        @qw-spacing-xxx-large; margin: @qw-spacing-xxx-large-rem }
.qw-mt-xxx-large { margin-top:    @qw-spacing-xxx-large; margin-top: @qw-spacing-xxx-large-rem }
.qw-mr-xxx-large { margin-right:  @qw-spacing-xxx-large; margin-right: @qw-spacing-xxx-large-rem }
.qw-mb-xxx-large { margin-bottom: @qw-spacing-xxx-large; margin-bottom: @qw-spacing-xxx-large-rem }
.qw-ml-xxx-large { margin-left:   @qw-spacing-xxx-large; margin-left: @qw-spacing-xxx-large-rem }
.qw-mx-xxx-large { margin-left:   @qw-spacing-xxx-large; margin-left: @qw-spacing-xxx-large-rem; margin-right: @qw-spacing-xxx-large; margin-right: @qw-spacing-xxx-large-rem }
.qw-my-xxx-large { margin-top:    @qw-spacing-xxx-large; margin-top: @qw-spacing-xxx-large-rem; margin-bottom: @qw-spacing-xxx-large; margin-bottom: @qw-spacing-xxx-large-rem }

.qw-ml-auto { margin-left: auto }
.qw-mr-auto { margin-right: auto }
.qw-mx-auto { margin-left: auto; margin-right: auto; }

/* QW Padding */
.qw-p-none  { padding:        0 }
.qw-pt-none { padding-top:    0 }
.qw-pr-none { padding-right:  0 }
.qw-pb-none { padding-bottom: 0 }
.qw-pl-none { padding-left:   0 }
.qw-px-none { padding-left:   0; padding-right:  0 }
.qw-py-none { padding-top:    0; padding-bottom: 0 }

.qw-p-xxx-small  { padding:        @qw-spacing-xxx-small; padding: @qw-spacing-xxx-small-rem }
.qw-pt-xxx-small { padding-top:    @qw-spacing-xxx-small; padding-top: @qw-spacing-xxx-small-rem }
.qw-pr-xxx-small { padding-right:  @qw-spacing-xxx-small; padding-right: @qw-spacing-xxx-small-rem }
.qw-pb-xxx-small { padding-bottom: @qw-spacing-xxx-small; padding-bottom: @qw-spacing-xxx-small-rem }
.qw-pl-xxx-small { padding-left:   @qw-spacing-xxx-small; padding-left: @qw-spacing-xxx-small-rem }
.qw-px-xxx-small { padding-left:   @qw-spacing-xxx-small; padding-left: @qw-spacing-xxx-small-rem; padding-right: @qw-spacing-xxx-small; padding-right: @qw-spacing-xxx-small-rem }
.qw-py-xxx-small { padding-top:    @qw-spacing-xxx-small; padding-top: @qw-spacing-xxx-small-rem; padding-bottom: @qw-spacing-xxx-small; padding-bottom: @qw-spacing-xxx-small-rem }

.qw-p-xx-small  { padding:        @qw-spacing-xx-small; padding: @qw-spacing-xx-small-rem }
.qw-pt-xx-small { padding-top:    @qw-spacing-xx-small; padding-top: @qw-spacing-xx-small-rem }
.qw-pr-xx-small { padding-right:  @qw-spacing-xx-small; padding-right: @qw-spacing-xx-small-rem }
.qw-pb-xx-small { padding-bottom: @qw-spacing-xx-small; padding-bottom: @qw-spacing-xx-small-rem }
.qw-pl-xx-small { padding-left:   @qw-spacing-xx-small; padding-left: @qw-spacing-xx-small-rem }
.qw-px-xx-small { padding-left:   @qw-spacing-xx-small; padding-left: @qw-spacing-xx-small-rem; padding-right: @qw-spacing-xx-small; padding-right: @qw-spacing-xx-small-rem }
.qw-py-xx-small { padding-top:    @qw-spacing-xx-small; padding-top: @qw-spacing-xx-small-rem; padding-bottom: @qw-spacing-xx-small; padding-bottom: @qw-spacing-xx-small-rem }

.qw-p-x-small  { padding:        @qw-spacing-x-small; padding: @qw-spacing-x-small-rem }
.qw-pt-x-small { padding-top:    @qw-spacing-x-small; padding-top: @qw-spacing-x-small-rem }
.qw-pr-x-small { padding-right:  @qw-spacing-x-small; padding-right: @qw-spacing-x-small-rem }
.qw-pb-x-small { padding-bottom: @qw-spacing-x-small; padding-bottom: @qw-spacing-x-small-rem }
.qw-pl-x-small {
  padding-left:   @qw-spacing-x-small;
  padding-left: @qw-spacing-x-small-rem;
  @media screen and (max-width: 991px) {
    padding-left: 2px;
   }
}
.qw-px-x-small { padding-left:   @qw-spacing-x-small; padding-left: @qw-spacing-x-small-rem; padding-right: @qw-spacing-x-small; padding-right: @qw-spacing-x-small-rem }
.qw-py-x-small { padding-top:    @qw-spacing-x-small; padding-top: @qw-spacing-x-small-rem; padding-bottom: @qw-spacing-x-small; padding-bottom: @qw-spacing-x-small-rem }

.qw-p-small  { padding:        @qw-spacing-small; padding: @qw-spacing-small-rem }
.qw-pt-small { padding-top:    @qw-spacing-small; padding-top: @qw-spacing-small-rem }
.qw-pr-small { padding-right:  @qw-spacing-small; padding-right: @qw-spacing-small-rem }
.qw-pb-small { padding-bottom: @qw-spacing-small; padding-bottom: @qw-spacing-small-rem }
.qw-pl-small { padding-left:   @qw-spacing-small; padding-left: @qw-spacing-small-rem }
.qw-px-small { padding-left:   @qw-spacing-small; padding-left: @qw-spacing-small-rem; padding-right: @qw-spacing-small; padding-right: @qw-spacing-small-rem }
.qw-py-small { padding-top:    @qw-spacing-small; padding-top: @qw-spacing-small-rem; padding-bottom: @qw-spacing-small; padding-bottom: @qw-spacing-small-rem }

.qw-p-medium  { padding:        @qw-spacing-medium; padding: @qw-spacing-medium-rem }
.qw-pt-medium { padding-top:    @qw-spacing-medium; padding-top: @qw-spacing-medium-rem }
.qw-pr-medium { padding-right:  @qw-spacing-medium; padding-right: @qw-spacing-medium-rem }
.qw-pb-medium { padding-bottom: @qw-spacing-medium; padding-bottom: @qw-spacing-medium-rem }
.qw-pl-medium { padding-left:   @qw-spacing-medium; padding-left: @qw-spacing-medium-rem }
.qw-px-medium { padding-left:   @qw-spacing-medium; padding-left: @qw-spacing-medium-rem; padding-right: @qw-spacing-medium; padding-right: @qw-spacing-medium-rem }
.qw-py-medium { padding-top:    @qw-spacing-medium; padding-top: @qw-spacing-medium-rem; padding-bottom: @qw-spacing-medium; padding-bottom: @qw-spacing-medium-rem }

.qw-p-large  { padding:        @qw-spacing-large; padding: @qw-spacing-large-rem }
.qw-pt-large { padding-top:    @qw-spacing-large; padding-top: @qw-spacing-large-rem }
.qw-pr-large { padding-right:  @qw-spacing-large; padding-right: @qw-spacing-large-rem }
.qw-pb-large { padding-bottom: @qw-spacing-large; padding-bottom: @qw-spacing-large-rem }
.qw-pl-large { padding-left:   @qw-spacing-large; padding-left: @qw-spacing-large-rem }
.qw-px-large { padding-left:   @qw-spacing-large; padding-left: @qw-spacing-large-rem; padding-right: @qw-spacing-large; padding-right: @qw-spacing-large-rem }
.qw-py-large { padding-top:    @qw-spacing-large; padding-top: @qw-spacing-large-rem; padding-bottom: @qw-spacing-large; padding-bottom: @qw-spacing-large-rem }

.qw-p-x-large  { padding:        @qw-spacing-x-large; padding: @qw-spacing-x-large-rem }
.qw-pt-x-large { padding-top:    @qw-spacing-x-large; padding-top: @qw-spacing-x-large-rem }
.qw-pr-x-large { padding-right:  @qw-spacing-x-large; padding-right: @qw-spacing-x-large-rem }
.qw-pb-x-large { padding-bottom: @qw-spacing-x-large; padding-bottom: @qw-spacing-x-large-rem }
.qw-pl-x-large {
  padding-left:@qw-spacing-x-large;
  padding-left: @qw-spacing-x-large-rem;
  @media screen and (max-width: 991px) {
    padding-left: 4px;
   }
}
.qw-px-x-large { padding-left:   @qw-spacing-x-large; padding-left: @qw-spacing-x-large-rem; padding-right: @qw-spacing-x-large; padding-right: @qw-spacing-x-large-rem }
.qw-py-x-large { padding-top:    @qw-spacing-x-large; padding-top: @qw-spacing-x-large-rem; padding-bottom: @qw-spacing-x-large; padding-bottom: @qw-spacing-x-large-rem }

.qw-p-xx-large  { padding:        @qw-spacing-xx-large; padding: @qw-spacing-xx-large-rem }
.qw-pt-xx-large { padding-top:    @qw-spacing-xx-large; padding-top: @qw-spacing-xx-large-rem }
.qw-pr-xx-large { padding-right:  @qw-spacing-xx-large; padding-right: @qw-spacing-xx-large-rem }
.qw-pb-xx-large { padding-bottom: @qw-spacing-xx-large; padding-bottom: @qw-spacing-xx-large-rem }
.qw-pl-xx-large { padding-left:   @qw-spacing-xx-large; padding-left: @qw-spacing-xx-large-rem }
.qw-px-xx-large { padding-left:   @qw-spacing-xx-large; padding-left: @qw-spacing-xx-large-rem; padding-right: @qw-spacing-xx-large; padding-right: @qw-spacing-xx-large-rem }
.qw-py-xx-large { padding-top:    @qw-spacing-xx-large; padding-top: @qw-spacing-xx-large-rem; padding-bottom: @qw-spacing-xx-large; padding-bottom: @qw-spacing-xx-large-rem }

.qw-p-xxx-large  { padding:        @qw-spacing-xxx-large; padding: @qw-spacing-xxx-large-rem }
.qw-pt-xxx-large { padding-top:    @qw-spacing-xxx-large; padding-top: @qw-spacing-xxx-large-rem }
.qw-pr-xxx-large { padding-right:  @qw-spacing-xxx-large; padding-right: @qw-spacing-xxx-large-rem }
.qw-pb-xxx-large { padding-bottom: @qw-spacing-xxx-large; padding-bottom: @qw-spacing-xxx-large-rem }
.qw-pl-xxx-large { padding-left:   @qw-spacing-xxx-large; padding-left: @qw-spacing-xxx-large-rem }
.qw-px-xxx-large { padding-left:   @qw-spacing-xxx-large; padding-left: @qw-spacing-xxx-large-rem; padding-right: @qw-spacing-xxx-large; padding-right: @qw-spacing-xxx-large-rem }
.qw-py-xxx-large { padding-top:    @qw-spacing-xxx-large; padding-top: @qw-spacing-xxx-large-rem; padding-bottom: @qw-spacing-xxx-large; padding-bottom: @qw-spacing-xxx-large-rem }


/* QW Position */
.qw-relative { position: relative }
.qw-absolute { position: absolute }
.qw-fixed    { position: fixed }

.qw-top-0    { top: 0 }
.qw-right-0  { right: 0 }
.qw-bottom-0 { bottom: 0 }
.qw-left-0   { left: 0 }

/* QW Icon Size */
.qw-icon-x-small { width: @qw-icon-x-small; width: @qw-icon-x-small-rem; height: @qw-icon-x-small; height: @qw-icon-x-small-rem; }
.qw-icon-small { width: @qw-icon-small; width: @qw-icon-small-rem; height: @qw-icon-small; height: @qw-icon-small-rem; }
.qw-icon-medium { width: @qw-icon-medium; width: @qw-icon-medium-rem; height: @qw-icon-medium; height: @qw-icon-medium-rem; }
.qw-icon-large { width: @qw-icon-large; width: @qw-icon-large-rem; height: @qw-icon-large; height: @qw-icon-large-rem; }
.qw-icon-x-large { width: @qw-icon-x-large; width: @qw-icon-x-large-rem; height: @qw-icon-x-large; height: @qw-icon-x-large-rem; }
.qw-icon-xxx-large { width: @qw-icon-xxx-large; width: @qw-icon-xxx-large-rem; height: @qw-icon-xxx-large; height: @qw-icon-xxx-large-rem; }


/* QW Type Scale */
.qw-font-size-xx-small { font-size: @qw-font-size-xx-small; font-size: @qw-font-size-xx-small-rem }
.qw-font-size-x-small { font-size: @qw-font-size-x-small; font-size: @qw-font-size-x-small-rem }
.qw-font-size-small { font-size: @qw-font-size-small; font-size: @qw-font-size-small-rem }
.qw-font-size-body { font-size: @qw-font-size-body; font-size: @qw-font-size-body-rem }
.qw-font-size-medium { font-size: @qw-font-size-medium; font-size: @qw-font-size-medium-rem }
.qw-font-size-large { font-size: @qw-font-size-large; font-size: @qw-font-size-large-rem }
.qw-font-size-x-large { font-size: @qw-font-size-x-large; font-size: @qw-font-size-x-large-rem }

/* QW Typography */
.qw-font-family-inherit { font-family: inherit }
.qw-font-size-inherit { font-size: inherit }
.qw-text-decoration-none { text-decoration: none }

.qw-bold    { font-weight: @qw-font-weight-bold }
.qw-regular { font-weight: @qw-font-weight-regular }
.qw-italic  { font-style: italic }
.qw-caps    { text-transform: uppercase; letter-spacing: @qw-letter-spacing-uppercase; }
.qw-capitalize { text-transform: capitalize }

.qw-left-align   { text-align: left }
.qw-center       { text-align: center }
.qw-right-align  { text-align: right }
.qw-justify      { text-align: justify }

.qw-nowrap { white-space: nowrap }
.qw-break-word { word-wrap: break-word }

.qw-line-height-xx-small { line-height: @qw-line-height-xx-small; line-height: @qw-line-height-xx-small-rem }
.qw-line-height-x-small { line-height: @qw-line-height-x-small; line-height: @qw-line-height-x-small-rem }
.qw-line-height-small { line-height: @qw-line-height-small; line-height: @qw-line-height-small-rem }
.qw-line-height-body { line-height: @qw-line-height-body; line-height: @qw-line-height-body-rem }
.qw-line-height-medium { line-height: @qw-line-height-medium; line-height: @qw-line-height-medium-rem }
.qw-line-height-large { line-height: @qw-line-height-large; line-height: @qw-line-height-large-rem }
.qw-line-height-x-large { line-height: @qw-line-height-x-large; line-height: @qw-line-height-x-large-rem }
.qw-line-height-reset { line-height: @qw-line-height-reset}

.qw-list-style-none { list-style: none }
.qw-underline { text-decoration: underline }

.qw-truncate {
  max-width: 100%;
  overflow: hidden;
  white-space: normal;
  word-break: break-word;
}

.qw-list-reset {
  list-style: none;
  padding-left: 0;
}

/*QW Shdadow*/
.qw-shadow-raised { box-shadow: @qw-shadow-raised }
.qw-shadow-ovelay { box-shadow: @qw-shadow-overlay }
.qw-shadow-sticky { box-shadow: @qw-shadow-sticky }
.qw-shadow-popout { box-shadow: @qw-shadow-popout }

/* cursor */
.qw-cursor-pointer { cursor: pointer; }
.qw-pointer-none { pointer-events: none; cursor: default; }

/*white space */
.qw-white-space-normal {  white-space:normal;  }
