@import 'qw-theme';
:root{
  font-size: 16px;
}
html,
body {
  min-height: 100%;
  padding: 0;
  margin: 0;
  font-family: @qw-font-family-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

//* DefaultLayout *//
.main-header {
  height: 60px;
  background: @qw-color-brand;
}

.nav-menu {
  background-color: transparent;
  height: 60px;
}

.nav-menu .nav-menu-item {
  height: 100%;
}

.page-loader {
  margin: auto;
}

.layout-wrapper {
  width: 100%;
  height: 100vh;
}

//* MoviePage *//
.main-content {
  min-height: 100%;
  height: 100vh;
  width: 75%;
  @media screen and (max-width: 991px) {
    width: 100%;
  }
  background: @qw-color-app-background;
}

//* filter overview *//

.filter-main-list {
  width: 25%;
  @media screen and (max-width: 991px) {
    width: 0%;
    display: none;
  }
}
.antd-modal-style-override {
  width: 740px;
  @media screen and (max-width: 991px) {
    top: 0;
    padding-bottom: 0;
    margin: 0;
  }
}
.movie-page {
  height: 100%;
  width: 100%;
  min-height: 100vh;

  .composition-list-view {
    width: 100%;    
    @media screen and (max-width: 991px) {
      background-color: #f7f7f7;
      padding-top: 5px;
    }
    /// dddddddrrrrrrrr
    // .composition-with-filter {
    //   width: 75%;
    //   background-color: #f7f7f7;
    //   @media screen and (max-width: 991px) {
    //     width: 100%;
    //   }
    // }
    // .composition-without-filter {
    //   width: 100%;
    //   background-color: #f7f7f7;
    //   @media screen and (max-width: 991px) {
    //     width: 100%;
    //   }
    // }
    // .filter-fullview {
    //   width: 25%;
    //   position: absolute;
    //   right: 0%;
    //   transition: right 0.3s;
    //   /// nnnnnnn
    //   position: fixed;
    //   top: 60px;
    //   right: 0;
    //   height: 100vh;
    //   @media screen and (max-width: 991px) {
    //     display: none;
    //   }
    // }
    // .filter-hiddenview {
    //   width: 5%;
    //   @media screen and (max-width: 991px) {
    //     display: none;
    //   }
    // }
    // .filter-panel-mobile {
    //   @media screen and (max-width: 991px) {
    //     width: 100%;
    //     position: absolute;
    //     right: -100%;
    //     transition: right 0.3s;
    //   }
    // }
    // .close-filter {
    //   right: 0;
    // }
  }
}

.movie-page-bar {
  height: 60px;
  background-color: @primary-1;
}

.movie-page-bar .movie-title {
  height: 60px;
}

.tabs-list {
  margin-bottom: 0;
}
.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 12px 0px;
  text-align: center;
  background-color: white;
  font-size: @qw-font-size-small;
  border-top: 1px solid @qw-color-table-border !important;
}
